<template>
  <div id="BIPlatform">
    <!-- BIPlatform首页头部 -->
    <div class="BIPlatformHeader">
      <!-- 头部组件 -->
      <Header></Header>
      <div class="texts">
        <div class="text1">三象BI平台</div>
        <div class="text2"></div>
        <div class="text3">
          三象BI平台提供对数据的查询、可视化、预警等核心功能，支持多种数据源接入。可以帮助企业分析、探索问题，挖掘数据中的价值，创建清晰、美观的数据看板。
        </div>
      </div>
    </div>
    <!-- BICenter中间内容 -->
    <div class="BICenter">
      <!-- 数据驱动业务 -->
      <div class="DataDrvSer">
        <div class="DataDrvSerTit">
          数据驱动业务
        </div>
        <div class="DataDrvSerText">
          实现数据规律的可视、可控、可优
        </div>
        <ul class="DataDrvSerContent">
          <li v-for="(item, index) in DataDrvSerContent" :key="index">
            <img :src="item.DataDrvSerImg" alt="" />
            <div class="DataDrvSerImgPro">{{ item.textg }}</div>
          </li>
        </ul>
      </div>
      <!-- 产品特性 -->
      <div class="proFeature">
        <div class="proFeatureTit">产品特性</div>
        <div class="proFeatureContent">
          <img v-if="currentIndex === 0" src="~assets/img/BIPlatform/用问题驱动分析@2x.png" alt="" />
          <img
            v-if="currentIndex === 1"
            src="~assets/img/BIPlatform/多数据来源支持@2x.png"
            alt="多数据来源支持"
          />
          <img
            v-if="currentIndex === 2"
            src="~assets/img/BIPlatform/丰富的图标类型@2x.png"
            alt="丰富的图标类型"
          />
          <div class="proFeatureConCenter">
            <img  v-if="currentIndex === 0"
              src="~assets/img/BIPlatform/icon-用问题驱动分析@2x.png"
              alt=""
            />
            <img
              v-if="currentIndex === 1"
              src="~assets/img/BIPlatform/icon-多数据来源支持@2x.png"
              alt="多数据来源支持"
            />
            <img
              v-if="currentIndex === 2"
              src="~assets/img/BIPlatform/icon-图标类型@2x.png"
              alt="图标类型"
            />
            <div v-if="currentIndex === 0" class="proFeaCenterTit" >
              提出一个问题，通过拖拽数据字段快速找到问题的答案
            </div>
            <div v-if="currentIndex === 1" class="proFeaCenterTit">
              支持多种不同来源的数据类型
            </div>
            <div v-if="currentIndex === 2" class="proFeaCenterTit">
              通过多样的图表类型，展示不同的数据结果
            </div>
            <div  v-if="currentIndex === 0" class="proFeaCenterContent">
              ·快速发现数据中潜藏的业务风险。<br> ·更准确的捕捉背后的增长点。
            </div>
            <div v-if="currentIndex === 1" class="proFeaCenterContent">
              ·连接常见的SQL数据库如Oracle、MySQL、Microsoft SQL Server。<br>
              ·对非关系型数据库NoSQL、Cloudant分析。<br> ·支持Json格式数据分析。
            </div>
            <div v-if="currentIndex === 2" class="proFeaCenterContent">
              ·表达清晰明确的柱状图、折线图。<br>
              ·适用于工业生产展示的时间轴停机图、设备状态矩阵图等。
            </div>
          </div>
          <div class="proFeatureRight">
            <ul class="proFeaRightContent">
              <li v-for="(item, index) in proFeaRightContent" :key="index"
               :class="currentIndex===index?'change':''"
                @click="changeCon(index)"
              >
                {{ item.texts }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 实现数据价值 -->
      <div class="dataValue">
        <div class="dataValueTit">实现数据价值</div>
        <div class="dataValueText">
          让每个决策都有数据支撑，让每个数据都发挥价值
        </div>
        <div class="dataValueContent">
          <img src="~assets/img/BIPlatform/实现数据价值@2x.png" alt="" />
        </div>
      </div>
    </div>
    <Footer />
    <!-- 回到顶部 -->
    <el-backtop
      ><img
        src="~assets/img/BIPlatform/icon-一键置顶@2x.png"
        alt="icon-回到顶部@2x.png"
    /></el-backtop>
  </div>
</template>
<script>
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
export default {
  name: "IOTPlatform",
  data() {
    return {
      DataDrvSerContent: [
        {
          DataDrvSerImg: require("assets/img/BIPlatform/icon-业务数字化@2x.png"),
          textg: "业务数字化"
        },
        {
          DataDrvSerImg: require("assets/img/BIPlatform/icon-场景数字化@2x.png"),
          textg: "场景数字化"
        },
        {
          DataDrvSerImg: require("assets/img/BIPlatform/icon-管理数字化@2x.png"),
          textg: "管理数字化"
        },
        {
          DataDrvSerImg: require("assets/img/BIPlatform/icon-决策数字化@2x.png"),
          textg: "决策数字化"
        }
      ],
      proFeaRightContent: [
        { texts: "用问题驱动分析" },
        { texts: "多数据来源支持" },
        { texts: "丰富的图表重量" }
      ],
       currentIndex: 0,
    };
  },
  components: {
    Header,
    Footer
  },
    methods: {
    changeCon(n) {
      this.currentIndex = n;
      console.log(this.currentIndex);
    }
  }
};
</script>
<style lang="less">
#BIPlatform {
   width: 1583px;
  background-color: #f8f9fb;
  margin: 0 auto;
  // BIPlatformHeader头部样式
  .BIPlatformHeader {
   width: 1583px;
    height: 620px;
    background: url("~assets/img/BIPlatform/banner@2x.png") no-repeat;
     background-size: 1583px 620px;
    background-position: center;
    margin: 0 auto;
    // margin: 0 auto;
    .texts {
      margin-left: 140px;
      .text1 {
        width: 221px;
        height: 38px;
        font-size: 38px;
        font-family: Source Han Sans CN;
        font-weight: 800;
        line-height: 25px;
        color: #1e90ff;
        opacity: 1;
        margin-top: 151px;
      }
      .text2 {
        width: 100px;
        height: 10px;
        background: #1e90ff;
        opacity: 1;
        border-radius: 5px;
        margin-top: 11px;
      }
      .text3 {
        width: 489px;
        height: 143px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 36px;
        color: rgba(51, 51, 51, 0.65);
        opacity: 1;
        margin-top: 20px;
      }
    }
  }
  // BICenter中间内容
  .BICenter {
    margin: 80px auto 79px;
    // 数据驱动业务
    .DataDrvSer {
      text-align: center;
      .DataDrvSerTit {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: rgba(51, 51, 51, 0.85);
      }
      .DataDrvSerText {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: rgba(51, 51, 51, 0.85);
        margin-top: 28px;
      }
      .DataDrvSerContent {
        width: 1220px;
        height: 180px;
        margin: 30px auto 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        li {
          width: 200px;
          height: 180px;
          background-color: #fff;
          img {
            width: 66px;
            height: 66px;
            margin-top: 45px;
          }
          .DataDrvSerImgPro {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            margin-top: 19px;
          }
        }
      }
    }
    // 产品特性
    .proFeature {
      margin-top: 80px;
      .proFeatureTit {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: rgba(51, 51, 51, 0.85);
        text-align: center;
      }
      .proFeatureContent {
        position: relative;
        width: 1220px;
        height: 300px;
        margin: 30px auto;
        background: #fff;
        display: flex;

        img {
          width: 400px;
          height: 240px;
          margin: 30px 0 30px 30px;
        }
        .proFeatureConCenter {
          margin-left: 60px;
          img {
            width: 36px;
            height: 36px;
            margin: 50px 0 0px;
          }
          .proFeaCenterTit {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 800;
            color: rgba(51, 51, 51, 0.85);
            margin-top: 20px;
          }
          .proFeaCenterContent {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 26px;
            color: rgba(51, 51, 51, 0.65);
            margin-top: 30px;
          }
        }
        .proFeatureRight {
          position: absolute;
          width: 212px;
          height: 300px;
          background: #e7f3fe;
          opacity: 1;
          border-radius: 10px;
          // margin-left: 118px;
          right: 0;

          .proFeaRightContent {
            text-align: center;
            padding: 0;
            li {
              margin: 50px auto;
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: rgba(51, 51, 51, 0.85);
              cursor: pointer;

           /*    &:hover {
                color: #1e90ff;
                font-weight: 500;
              } */
            }
            .change{
              color: #1e90ff;
                font-weight: 500;
                border-right: 5px solid #1e90ff;
            }
          }
        }
      }
    }
    // 实现数据价值
    .dataValue {
      margin: 80px auto 0;
      text-align: center;
      .dataValueTit {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: rgba(51, 51, 51, 0.85);
      }
      .dataValueText {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: rgba(51, 51, 51, 0.85);
        margin-top: 28px;
      }
      .dataValueContent {
        width: 1220px;
        height: 502px;
        margin: 34px auto 0;
        background-color: #fff;
        img {
          width: 1220px;
          height: 502px;
        }
      }
    }
  }
}
.el-backtop {
  img {
    width: 30px;
    height: 30px;
  }
}
</style>
